import { Injectable } from '@angular/core';
import { HttpClientModule ,HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http'; 
import {Http,Headers,RequestOptions,ResponseContentType,HttpModule} from '@angular/http';
//import { tokenNotExpired } from 'angular-jwt';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebService {
	authToken;
	user; 
	options;
	

  constructor(private http:Http, private router:Router ) { }
	private headers = new Headers({'Content-Type': 'application/json'});

	login(url,user){ 
    	return this.http.post(url,user).toPromise();
 	}

  
   //this is post request
	getRequest(url,obj){
    	this.createAuthenticationHeaders();
  		return this.http.post(url,obj,this.options).toPromise() 
    }

   postFileWithDataRequest(url,obj:any){
     this.loadToken();
      this.options =new RequestOptions({
        headers: new Headers({
          'authorization':this.authToken, //authorization you can chage same name has to be acces in router middleware 
        })
      })
     return this.http.post(url,obj,this.options).toPromise()
   } 
   //get responce
    getResponce(url){
   		this.createAuthenticationHeaders();
    	return this.http.get(url,this.options).toPromise();
    }

    //store user data in local storage
    storeUserData(token,user){
	  	localStorage.setItem('token',token);
	  	localStorage.setItem('user',JSON.stringify(user));
	  	this.authToken= token;
	  	this.user= user; 
  	}
    storeTempVariable(key,value){
      localStorage.setItem(key,value);
    }
  	//get Stored data from local storage

  	getStoreData(){

  	}
  	//check user login or not 
    loggedIn() {
      //return tokenNotExpired();
      console.log(localStorage.getItem('token')+'------girish')

      return !!localStorage.getItem('token');
      
    }
	// end 
	//logout the user
	logout(){
	   this.authToken = null;
	   this.user = null;
	   localStorage.clear();
     localStorage.removeItem('token');


	}
	//end here logged out
	createAuthenticationHeaders(){
	    this.loadToken();
	    this.options =new RequestOptions({
	      headers: new Headers({
	        'Content-Type':'application/json',
	        'authorization':this.authToken //authorization you can chage same name has to be acces in router middleware 
	    })
    });
   	
   }
   loadToken(){
     const token= localStorage.getItem('token');
     this.authToken = token;
   }
  FormDataRequest(url:string,files: any,obj:any){
  	let formData:FormData = new FormData();
  	console.log(url);
  	console.log(files[0]);
  	console.log(obj)
  	console.log(files[0]['name']);

  	 if(files[0] && files[0]['name']){
  	 	console.log("inside :" +files[0]['name'])
  	 	formData.append('uploads', files[0], files[0]['name']);
  	 	console.log(formData.get('uploads'))
  	 }
  	 console.log(formData.get('uploads'))
  	  for (var property in obj) {
              if (obj.hasOwnProperty(property)) {
                if(typeof(obj[property]) === 'object'){
                  formData.append(property, JSON.stringify(obj[property]));
                }else{
                  formData.append(property, obj[property]);
                }
                  
              }
          }
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.onload = function () {
          // do something to response
          console.log(xhr.responseText);
      };
      console.log(formData);
      xhr.send(formData);
 

  }

}


  