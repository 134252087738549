import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AppRoutingModule} from './app-routing.modules';
import { ObserverService } from './services/observerservices/observer.service';
import {WebService} from './services/web/web.service';  
import {SocketService} from './services/socket/socket.service';
import {DataService} from './services/data/data.service';  
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import {WebhosturlService} from './services/weburl/webhosturl.service'

import { WINDOW_PROVIDERS } from './services/window.providers';
@NgModule({
  declarations: [
    AppComponent 
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule
  ],
  providers: [ObserverService,WebService,DataService,SocketService,WINDOW_PROVIDERS,WebhosturlService],
  bootstrap: [AppComponent]
})
export class AppModule { }
