import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
//import {WebhosturlService} from '../../../services/weburl/webhosturl.service'
import {WebhosturlService} from '../../services/weburl/webhosturl.service'

import * as io from 'socket.io-client';
@Injectable({
  providedIn: 'root'
})
export class SocketService {
	//private url = 'http://localhost:4000/web'; 
  //private url = 'http://18.197.253.150:4000/web'; 
  //private url = 'http://adscms.wisefly.in/web'; //working Url for server
  private url;
  private hostNameUrl:string;
	private socket;  
  	constructor(private webhosturlService:WebhosturlService) { 
      //console.log(webhosturlService.getHostname() + "from soket server")
      this.hostNameUrl =webhosturlService.getHostname();

      if(this.hostNameUrl=="localhost")
        this.url ='http://localhost:8000/web'; 
      else
        this.url = 'http://adscms.wisefly.in/web';

		  this.socket = io(this.url);
    	this.socket.on('connect',(socket)=>{
    	  console.log("socket connected...!");
    	})

  	}
  	sendMessage(obj){//this orgId when perticuler organizational admin login
  		console.log("connection with socket send message");
    	this.socket.emit('airportname', obj);    
  	}
  	leavePreRoom(obj){
  		console.log("first leave previous Room here");
  		this.socket.emit("leaveroom",obj)
  	}
  	 getMessages() {
	    let observable = new Observable(observer => {
	      
	      this.socket.on('updateWebClient', (data) => { // here we have to replace as per org login  orgId has to be change
	        console.log("got an update");
	        observer.next(data);    
	      });
	      return () => {
	        this.socket.disconnect();
	      };  
	    })     
	    return observable;
  	}  
}
 