import { NgModule } from '@angular/core';
import { RouterModule, Routes,CanLoad } from '@angular/router';

const appRoutes: Routes = [
{ path: 'masteradmin', loadChildren: './master-admin/master-admin.module#MasterAdminModule' },
{ path: 'clientadmin', loadChildren: './client-admin/client-admin.module#ClientAdminModule' },
]
 
@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes)],
  providers: [],
  bootstrap: [],
  exports:[RouterModule]

})   

export class AppRoutingModule { } 