import { Injectable,Inject } from '@angular/core';
import { WINDOW } from '../window.providers';
@Injectable({
  providedIn: 'root'
})
export class WebhosturlService {

  constructor(@Inject(WINDOW) private window: Window) {
   }
    getHostname() : string {
        return this.window.location.hostname;
    }
}
