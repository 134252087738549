import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  serviceData: Array<object>=[];
  constructor() { }
  getdata() {
         console.log("get data");
        return this.serviceData;
    }
  setdata(value: any) {
      console.log("set data")
        this.serviceData.push(value);
    }
}
