import { Injectable } from '@angular/core';
//import {Subject} from 'rxjs/Subject';
import * as Rx from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ObserverService {

	//navbar:Subject<boolean>= new Subject()
	
	navbar = new Rx.Subject();
	selectedAirport:any = new Rx.Subject();


	

  constructor() { }
}
